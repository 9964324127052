import React from "react"

const Preloader = ({isEn}) => (
  <section id="preloader">
    <div id="inner">
      <svg
        version="1.1"
        id="logopreloader"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="148.009px"
        height="67.606px"
        viewBox="2.295 3.144 148.009 67.606"
        enableBackground="new 2.295 3.144 148.009 67.606"
      >
        <path
          className="preloader2"
          fill="#9c0059"
          d="M58.832,9.057c0,0-3.887,0.017-3.887,0c0-0.004,0.906-3.576,0.906-3.576s-3.787-2.053-4.422-2.335
				  c-0.068,0.066-20.77,67.429-20.77,67.429h4.85c0,0,7.549-23.951,10.166-32.432c6.561,4.675,17.539,12.766,17.539,12.766
				  l5.389,19.841c0,0,5.047-0.015,5.211,0C73.814,70.749,59.158,9.976,58.832,9.057z M61.59,42.998
				  c-2.994-2.674-12.707-8.675-14.033-9.808c0,0,4.68-16.057,6.482-21.841C54.756,15.215,61.59,42.998,61.59,42.998z"
        />
        <path
          className="preloader3"
          fill="#9c0059"
          d="M27.691,5.48c0,0-4.15-2.379-4.684-2.335C20.164,5.859,7.777,18.108,6.594,19.169
				  c-3.197,2.856-2.068,6.388-0.828,7.971c2.859,3.648,11.707,16.228,12.684,17.65c2.049,2.984,1.051,4.16-0.434,5.871
				  C17.705,51.019,2.359,69.905,2.295,70.75h6.457c0,0,12.967-16.382,15.002-19.563c1.586-2.481,1.902-4.409,0.037-7.525
				  c-1.941-3.242-10.033-13.63-12.303-16.94c-1.814-2.65-2.139-3.167,0.432-5.735C14.992,17.915,27.691,5.48,27.691,5.48z"
        />
        <path
          className="preloader1"
          fill="#9c0059"
          d="M135.322,9.057c0,0-3.887,0.017-3.887,0c0-0.004,0.904-3.147,0.904-3.147s-3.785-2.481-4.42-2.764
				  c-0.068,0.066-20.771,67.429-20.771,67.429H112c0,0,3.531-11.539,6.148-20.02c6.625-4.135,18.707-12.406,18.707-12.406
				  l8.238,32.601c0,0,5.047-0.015,5.211,0C150.305,70.749,135.648,9.976,135.322,9.057z M135.955,33.292
				  c-1.799,0.831-11.244,7.137-15.824,9.706c0,0,8.596-25.864,10.396-31.648C131.248,15.215,135.955,33.292,135.955,33.292z"
        />
        <path
          className="preloader4"
          fill="#9c0059"
          d="M104.99,5.48c0,0-4.148-2.379-4.682-2.335c-2.846,2.714-15.23,14.963-16.416,16.023
				  c-3.197,2.856-2.068,6.388-0.826,7.971c2.859,3.648,11.705,16.228,12.682,17.65c2.049,2.984,1.053,4.16-0.434,5.871
				  c-0.308,0.359-15.654,19.245-15.718,20.09h6.457c0,0,12.967-16.382,15-19.563c1.588-2.481,1.904-4.409,0.037-7.525
				  c-1.939-3.242-10.031-13.63-12.301-16.94c-1.816-2.65-2.139-3.167,0.432-5.735C92.293,17.915,104.99,5.48,104.99,5.48z"
        />
      </svg>
    </div>
  </section>
)

export default Preloader
