import React from "react";

export default () => (
  <div>
    <script type={'application/ld+json'}
            dangerouslySetInnerHTML={{
              __html: `{
        "@context":"http://schema.org",
        "@type":"WebSite",
        "@id":"#website",
        "url":"https://sasafoundation.com",
        "name":"Fundacji SASA - pomoc potrzebującym placówkom medycznym na całym świecie"
      }`
            }}/>
    <script type={'application/ld+json'}
            dangerouslySetInnerHTML={{
              __html: `{
        "@context":"http://schema.org",
        "@type":"Organization",
        "url":"https://sasafoundation.com",
        "sameAs":[
          "https://www.facebook.com/sasafoundation/",
          "https://www.instagram.com/sasafoundation/",
          "https://www.linkedin.com/company-beta/17908816/"
        ],
        "@id":"#organization",
        "name":"Fundacji SASA",
        "logo":""
      }`
            }}/>
  </div>
)
