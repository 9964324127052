import React from "react"
import { Link, withPrefix } from "gatsby"
import PIT from "@svg/pit"
import { pl, en } from "@content/menu.yaml"

const Menu = ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <nav className="mobilemenu">
      <div className="inner">
        <div className="mobilemenu_btn_content mobilemenu_close">
          <div className="mobilemenu_btn open">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <small>zamknij</small>
        </div>
        <span className="l1"></span>
        <span className="l2"></span>
        <span className="l3"></span>
        <span className="l4"></span>

        <div className="menu_content">
          <div className="menu_content_inner">
            <div className="row">
              <div className="large-4 medium-12 small-12 columns text-center large-text-left">
                <strong>{translation.we_title}</strong>
                <div className="menu-menu-my-container">
                  <ul id="menu-menu-my" className="menu">
                    {translation.we.map((item, key) => (
                      <li
                        key={key}
                        className="menu-item menu-item-type-post_type menu-item-object-page"
                      >
                        {item.popup && (
                          <a
                            href="#"
                            data-borderpopup={`borderpopup-${item.popup}`}
                            className="open-borderpopup"
                          >
                            {item.value}
                          </a>
                        )}
                        {!item.popup && (
                          <Link to={item.link}>{item.value}</Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="large-4 medium-12 small-12 columns text-center large-text-left">
                <strong>{translation.activity_title}</strong>
                <div className="menu-menu-pomoc-container">
                  <ul id="menu-menu-pomoc" className="menu">
                    <li
                      id="menu-item-652"
                      className="popup-pomoc font-normal open-borderpopup help menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-652"
                    >
                      <a
                        href="https://sasafoundation.com/#"
                        aria-current="page"
                        data-borderpopup="borderpopup-pomoc"
                        className="open-borderpopup"
                      >
                        {translation.activity_subtitle}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="menu-menu-dzialanosc-container">
                  <ul id="menu-menu-dzialanosc" className="menu">
                    {translation.activity.map((item, key) => (
                      <li
                        key={key}
                        className="menu-item menu-item-type-post_type menu-item-object-page"
                      >
                        {item.popup && (
                          <a
                            href="#"
                            data-borderpopup={`borderpopup-${item.popup}`}
                            className="open-borderpopup"
                          >
                            {item.value}
                          </a>
                        )}
                        {!item.popup && (
                          <Link to={item.link}>{item.value}</Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="show-for-large text-center"></div>
              </div>
              <div className="large-4 medium-12 small-12 columns text-center large-text-left">
                <strong>{translation.projects_title}</strong>
                <div className="menu-menu-projekty-container">
                  <ul id="menu-menu-projekty" className="menu">
                    {translation.projects.map((item, key) => (
                      <li
                        key={key}
                        className="menu-item menu-item-type-post_type menu-item-object-page"
                      >
                        {item.popup && (
                          <a
                            href="#"
                            data-borderpopup={`borderpopup-${item.popup}`}
                            className="open-borderpopup"
                          >
                            {item.value}
                          </a>
                        )}
                        {!item.popup && (
                          <Link to={item.link}>{item.value}</Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {!isEn && (
                <div className="large-12 medium-12 small-12 columns text-center">
                  <div className="one-procent-button krs">
                    <PIT />
                    <a
                      href="http://bit.ly/2E8LP70"
                      className="krs"
                      rel="dofollow"
                      target="_blank"
                    >
                      KRS 0000 552525
                    </a>
                    <a
                      href="http://bit.ly/2E8LP70"
                      rel="dofollow"
                      target="blank"
                    >
                      ROZLICZ Z NAMI PIT!{" "}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Menu
