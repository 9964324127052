import React from "react"

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.642 74.641">
    <g data-name="Group 21" transform="translate(-204.961 -201.763)">
      <g data-name="Group 19" transform="translate(204.961 201.763)">
        <path
          data-name="Path 35"
          d="M230.65,205.862h-2.7l.627-2.478s-2.625-1.426-3.066-1.621c-.048.048-14.4,46.761-14.4,46.761h3.361s5.238-16.611,7.051-22.492c4.55,3.242,12.163,8.854,12.163,8.854l3.738,13.759s3.5-.01,3.613,0C241.039,248.646,230.874,206.5,230.65,205.862Zm1.912,23.537c-2.075-1.855-8.812-6.015-9.733-6.8,0,0,3.246-11.134,4.5-15.144C227.823,210.134,232.562,229.4,232.562,229.4Z"
          transform="translate(-191.442 -201.763)"
          fill="#720a48"
        ></path>
        <path
          data-name="Path 36"
          d="M222.573,203.384a22.9,22.9,0,0,0-3.249-1.621c-1.973,1.883-10.562,10.376-11.383,11.112a3.96,3.96,0,0,0-.572,5.529c1.982,2.529,8.119,11.252,8.793,12.24,1.423,2.069.729,2.884-.3,4.07-.214.249-10.856,13.347-10.9,13.932h4.477s8.995-11.361,10.4-13.567c1.1-1.72,1.321-3.057.026-5.218-1.346-2.248-6.958-9.452-8.531-11.748-1.26-1.839-1.484-2.2.3-3.978C213.767,212.005,222.573,203.384,222.573,203.384Z"
          transform="translate(-204.961 -201.763)"
          fill="#720a48"
        ></path>
        <path
          data-name="Path 37"
          d="M247.241,205.862h-2.7l.63-2.184s-2.625-1.72-3.066-1.915c-.048.048-14.4,46.761-14.4,46.761h3.364s2.449-8,4.266-13.884c4.595-2.868,12.972-8.6,12.972-8.6l5.714,22.61s3.5-.01,3.613,0C257.63,248.646,247.465,206.5,247.241,205.862Zm.438,16.806c-1.247.579-7.8,4.95-10.974,6.731,0,0,5.96-17.935,7.211-21.945C244.415,210.134,247.679,222.669,247.679,222.669Z"
          transform="translate(-154.989 -201.763)"
          fill="#720a48"
        ></path>
        <path
          data-name="Path 38"
          d="M239.335,203.384a23.023,23.023,0,0,0-3.246-1.621c-1.973,1.883-10.562,10.376-11.383,11.112a3.958,3.958,0,0,0-.576,5.529c1.986,2.529,8.119,11.252,8.8,12.24,1.42,2.069.729,2.884-.3,4.07-.214.249-10.856,13.347-10.9,13.932H226.2s8.992-11.361,10.4-13.567c1.1-1.72,1.321-3.057.026-5.218-1.343-2.248-6.955-9.452-8.528-11.748-1.26-1.839-1.484-2.2.3-3.978C230.532,212.005,239.335,203.384,239.335,203.384Z"
          transform="translate(-168.119 -201.763)"
          fill="#720a48"
        ></path>
      </g>
      <g data-name="Group 20" transform="translate(205.677 258.616)">
        <path
          data-name="Path 39"
          d="M206.8,233.527h-1.618V219.6h7.76v1.439H206.8v5.1h5.772v1.436H206.8Z"
          transform="translate(-205.185 -219.407)"
          fill="#720a48"
        ></path>
        <path
          data-name="Path 40"
          d="M219.32,219.6v9.011a4.914,4.914,0,0,1-1.436,3.741,5.516,5.516,0,0,1-3.952,1.362,5.27,5.27,0,0,1-3.891-1.372,5.1,5.1,0,0,1-1.375-3.77V219.6h1.618v9.088a3.566,3.566,0,0,0,.953,2.673,3.836,3.836,0,0,0,2.8.934,3.707,3.707,0,0,0,2.715-.937,3.607,3.607,0,0,0,.953-2.692V219.6Z"
          transform="translate(-197.535 -219.407)"
          fill="#720a48"
        ></path>
        <path
          data-name="Path 41"
          d="M224.4,233.527h-1.848l-7.607-11.684h-.077c.1,1.372.153,2.628.153,3.77v7.914h-1.5V219.6h1.829l7.588,11.636h.077c-.01-.169-.042-.719-.083-1.65s-.064-1.6-.051-2V219.6H224.4Z"
          transform="translate(-186.849 -219.407)"
          fill="#720a48"
        ></path>
        <path
          data-name="Path 42"
          d="M229.617,226.435a7.03,7.03,0,0,1-1.871,5.27,7.408,7.408,0,0,1-5.385,1.823h-3.856V219.6h4.266a6.251,6.251,0,0,1,6.846,6.83Zm-1.714.058a5.607,5.607,0,0,0-1.365-4.106,5.473,5.473,0,0,0-4.061-1.381h-2.353v11.121H222.1a5.982,5.982,0,0,0,4.349-1.423A5.627,5.627,0,0,0,227.9,226.493Z"
          transform="translate(-175.914 -219.407)"
          fill="#720a48"
        ></path>
        <path
          data-name="Path 43"
          d="M233.395,233.567l-1.733-4.425h-5.583l-1.711,4.425h-1.637l5.5-13.98h1.359l5.477,13.98Zm-2.238-5.883-1.618-4.313c-.208-.547-.425-1.218-.649-2.008a17.264,17.264,0,0,1-.6,2.008l-1.637,4.313Z"
          transform="translate(-166.627 -219.446)"
          fill="#720a48"
        ></path>
        <path
          data-name="Path 44"
          d="M234.024,220.988a4.547,4.547,0,0,0-3.623,1.532,6.159,6.159,0,0,0-1.33,4.182,6.286,6.286,0,0,0,1.282,4.224,4.551,4.551,0,0,0,3.652,1.49,12.355,12.355,0,0,0,3.322-.524v1.42a10.229,10.229,0,0,1-3.568.544,6.041,6.041,0,0,1-4.748-1.867,7.692,7.692,0,0,1-1.669-5.3,8.369,8.369,0,0,1,.8-3.77,5.791,5.791,0,0,1,2.325-2.494,7.02,7.02,0,0,1,3.575-.876,8.617,8.617,0,0,1,3.828.8l-.684,1.391A7.38,7.38,0,0,0,234.024,220.988Z"
          transform="translate(-156.496 -219.543)"
          fill="#720a48"
        ></path>
        <path
          data-name="Path 45"
          d="M232.128,237.195a3.171,3.171,0,0,1-1.41-.259v-1.381a5.126,5.126,0,0,0,1.41.192,1.8,1.8,0,0,0,1.432-.569,2.478,2.478,0,0,0,.489-1.65V219.6h1.618v13.788a3.981,3.981,0,0,1-.915,2.8A3.378,3.378,0,0,1,232.128,237.195Z"
          transform="translate(-149.075 -219.407)"
          fill="#720a48"
        ></path>
        <path
          data-name="Path 46"
          d="M243.865,233.567l-1.73-4.425h-5.583l-1.714,4.425H233.2l5.506-13.98h1.359l5.477,13.98Zm-2.235-5.883-1.618-4.313c-.211-.547-.425-1.218-.649-2.008a17.919,17.919,0,0,1-.6,2.008l-1.637,4.313Z"
          transform="translate(-143.618 -219.446)"
          fill="#720a48"
        ></path>
      </g>
    </g>
  </svg>
)
