import React from "react"
import { withPrefix } from "gatsby"
import { pl, en } from "@content/support.yaml"

const Support = ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <section className="wspiera">
      <div className="row">
        <div className="large-12 medium-12 small-12 columns text-left">
          <p>
            {translation.support}
            <a href="https://brandmed.com/" target="_blank" rel="dofollow">
              <img
                src={withPrefix("images/support/brandmed.svg")}
                alt="brandmed"
              />
            </a>
          </p>
        </div>
      </div>
    </section>
  )
}

export default Support
