import React from "react"
import PIT from "@svg/pit"

const Social = ({isEn}) => (
  <div className="socials-links">
    <div className="one-procent-button full-bg">
      <PIT />
      <a href="http://bit.ly/2E8LP70" target="_blank" rel="dofollow">
        ROZLICZ Z NAMI PIT!{" "}
      </a>
    </div>
  </div>
)

export default Social
