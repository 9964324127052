import React from "react"
import { Link } from "gatsby"
import WhiteArrow from "@svg/white-arrow.js"
import WhiteHeart from "@svg/white-heart.js"

const PopupPomoc = ({isEn}) => {
  return (
    <section
      id="borderpopup-pomoc"
      className="borderpopup redbg fadeIn animated"
    >
      <div className="inner">
        <span className="l1"></span>
        <span className="l2"></span>
        <span className="l3"></span>
        <span className="l4"></span>
        <div className="mobilemenu_btn_content borderpopup_close">
          <div className="mobilemenu_btn open">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <small> zamknij</small>
        </div>
        <div className="borderpopup_content">
          <div className="row expanded collapse">
            <div className="large-6 medium-12 small-12 columns">
              <div
                className="photobg with_text"
                style={{
                  backgroundImage: `url(/images/popup-pomoc/background.jpg)`,
                }}
              >
                <div className="borderpopup_text borderpopup-help white_text">
                  <div className="borderpopup_text_inner news-text small-text">
                    <div className="display-table small-block">
                      <div className="display-table-cell text-center">
                        <div className="help-height">
                          <a
                            href="#"
                            data-borderpopup="borderpopup-kontakt"
                            className="open-borderpopup margin-bottom25 rollover"
                          >
                            <span className="underlined">
                              <span className="roll-icon">
                                <WhiteArrow />
                                <WhiteHeart />
                              </span>
                            </span>
                            <h2 className="white big margin-bottom10">
                              {isEn ? 'Join us' : 'Dołącz do nas'}
                            </h2>
                            <p className="roll-show">
                              {isEn ? 'Join SASA Team, and become our volunteer' : 'Dołącz do zespołu Fundacji SASA i zostań naszym wolontariuszem!'}
                            </p>
                          </a>

                          <div className="nsl-frame transparent">
                            <form
                              action="//sasafoundation.us10.list-manage.com/subscribe/post?u=dddf51132b3e6f99bfadf3e42&amp;id=144f9c49af"
                              method="post"
                              name="mc-embedded-subscribe-form"
                              target="_blank"
                              id="sidebar_newsletter"
                            >
                              <div className="row expanded collapse">
                                <div className="large-12 columns">
                                  <span className="input input--madoka">
                                    <input
                                      className="input__field input__field--madoka email"
                                      type="text"
                                      name="EMAIL"
                                      id="wpisz-maila"
                                      defaultValue={""}
                                    />
                                    <label
                                      className="input__label input__label--madoka"
                                      htmlFor="wpisz-maila"
                                    >
                                      <svg
                                        className="graphic graphic--madoka"
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 404 77"
                                        preserveAspectRatio="none"
                                      >
                                        <path d="m0,0l404,0l0,77l-404,0l0,-77z"></path>
                                      </svg>
                                      <span className="input__label-content input__label-content--madoka">
                                        {isEn ? 'Sign up to our newsletter' : 'Zapisz się do naszego newslettera' }
                                      </span>
                                    </label>
                                    <input
                                      type="submit"
                                      name="subscribe"
                                      className="send fadeOutLeft animated"
                                      value=""
                                      style={{ display: "inline-block" }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </form>
                          </div>

                          <div className="social-media-popup">
                            <a
                              href="https://www.facebook.com/sasafoundation/"
                              target="_blank"
                              rel="nofollow"
                            >
                              <span>
                                <i className="fa fa-facebook"></i>
                              </span>
                            </a>
                            <a
                              href="https://twitter.com/sasa_foundation"
                              target="_blank"
                              rel="nofollow"
                            >
                              <span>
                                <i className="fa fa-twitter"></i>
                              </span>
                            </a>
                            <a
                              href="https://www.linkedin.com/company-beta/17908816/"
                              target="_blank"
                              rel="nofollow"
                            >
                              <span>
                                <i
                                  className="fa fa-linkedin"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </a>
                            <a
                              href="https://www.instagram.com/sasafoundation/"
                              target="_blank"
                              rel="nofollow"
                            >
                              <span>
                                <i
                                  className="fa fa-instagram"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="large-6 medium-12 small-12 columns text-left">
              <div className="borderpopup_text borderpopup-help white_text">
                <div className="borderpopup_text_inner news-text small-text">
                  <div className="display-table small-block">
                    <div className="display-table-cell text-center">
                      <div className="help-height">
                        <Link
                          to={isEn ? '/en/wspomoz-fundacje' : "/wspomoz-fundacje/"}
                          className="margin-bottom25 rollover"
                        >
                          <span className="underlined">
                            <span className="roll-icon">
                              <WhiteArrow />
                              <WhiteHeart />
                            </span>
                          </span>
                          <h2 className="white big margin-bottom10">
                            {isEn ? 'Donate us' : 'Przekaż darowiznę'}
                          </h2>
                          <p className="roll-show">
                            {isEn ? 'Support our actions - donating us' : 'Wesprzyj nasze inicjatywy przekazując środki finansowe - darowiznę!'}

                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default PopupPomoc
