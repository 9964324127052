import React from "react"

const PopupContact = ({isEn}) => {
  return (
    <section id="borderpopup-kontakt" className="borderpopup">
      <div className="inner">
        <span className="l1"></span>
        <span className="l2"></span>
        <span className="l3"></span>
        <span className="l4"></span>
        <div className="mobilemenu_btn_content borderpopup_close">
          <div className="mobilemenu_btn open">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <small>zamknij</small>
        </div>
        <div className="borderpopup_content">
          <div className="row expanded collapse">
            <div className="large-6 large-push-6 medium-12 small-12 columns show-for-large">
              <div
                className="photobg"
                style={{
                  backgroundImage: `url(/images/popup-contact/background.jpg)`,
                }}
              >
                &nbsp;
              </div>
            </div>
            <div className="large-6 large-pull-6 medium-12 small-12 columns text-left">
              <div className="borderpopup_text">
                <div className="borderpopup_text_inner news-text">
                  <div className="display-table small-block">
                    <div className="display-table-cell v-bottom kontakt-content">
                      <div className="thin h1">
                        {!isEn
                          ? "Masz pytania?"
                          : "Do you have any questions?"}
                        <strong className="display-block">
                          {!isEn
                            ? "Odezwij się, czekamy :)"
                            : "Write to us :)"}
                        </strong>
                      </div>
                      <div className="row collapse">
                        <div className="large-12 columns">
                          <p className="red">
                            <a
                              className="red"
                              href="mailto:hello@sasafoundation.com"
                            >
                              hello@sasafoundation.com
                            </a>
                          </p>
                        </div>
                        <div className="large-6 medium-12 small-12 columns">
                          <p className="red">
                            +48 509 058 906
                            <br />
                            +48 696 575 841
                          </p>
                        </div>
                        <div className="large-6 medium-12 small-12 columns">
                          <p className="red">
                            Fundacja SASA
                            <br />
                            ul. Benedykta Hertza 6 C / 1<br />
                            04-603 Warszawa
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PopupContact
