import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Menu from "./menu.js"
import Footer from "./footer.js"
import Support from "./support"
import Social from "./social"
import Cookies from "./cookies"
import PopupContact from "./popup-kontakt"
import PopupPomoc from "./popup-pomoc"
import Preloader from "./preloader";
import TableOfContents from './table-of-contents'

const Layout = ({ children, isEn }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Menu isEn={isEn}/>
      {children}
      <Footer isEn={isEn}/>
      <Support isEn={isEn}/>
      {!isEn && <Social />}
      <Cookies isEn={isEn}/>
      <Preloader isEn={isEn}/>
      <PopupContact isEn={isEn}/>
      <PopupPomoc isEn={isEn}/>
      <TableOfContents />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
