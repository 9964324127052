import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { withPrefix } from "gatsby-link"

function SEO({ description, lang, meta, title, isEn }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            title_en
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = isEn
    ? site.siteMetadata?.title_en
    : site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang: 'pl_PL',
      }}
      title={title ? title : defaultTitle}
      meta={[
        {
          name: `x-ua-compatible`,
          content: "id=edge",
        },
        {
          name: `viewport`,
          content: "width=device-width, initial-scale=1",
        },
        {
          name: `format-detection`,
          content: "telephone=no",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: `pl_PL`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: withPrefix('images/sasa-thumbnail.jpg')
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `https://sasafoundation.com/`,
        },
        {
          property: `og:site_name`,
          content: `SaSa | Crowdfunding medyczny`,
        },
        {
          property: `og:app_id`,
          content: `308476926025174`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification`,
          content: `FOcVo7MfoL9fLjAqamD8tqfegR_gI0kpG2bsC0_TSYI`,
        },
      ].concat(meta)}
      link={[
        {
          rel: "stylesheet",
          id: "sage/css-css",
          href: withPrefix("styles/main.css"),
        },
        {
          rel: "stylesheet",
          id: "sage/css-css",
          href: withPrefix("styles/smiley.css"),
        },
      ]}
      script={[
        {
          defer: true,
          src:
            "https://connect.facebook.net/pl_PL/sdk.js?hash=c63c05cd70a302d1fd8010e8c279db46&ua=modern_es6",
        },
        {
          defer: true,
          src: withPrefix("scripts/jquery.js"),
        },
        {
          defer: true,
          src: withPrefix("scripts/main.js"),
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
  description: ``,
}

export default SEO
