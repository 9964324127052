import React from "react"
import { withPrefix } from "gatsby"

const Cookies = ({isEn}) => (
  <div id="cookies-box">
    <div className="row">
      <div className="large-10 medium-10 small-10 columns">
        <p>
          {isEn ? `
          This website uses cookies.
          ` : `
          Ta strona używa ciasteczek (cookies), dzięki którym nasz serwis może
          działać lepiej.
          `
          }

          <span
            className="pokazdetale white underline"
            style={{ display: "inline-block" }}
          >
            {
              !isEn && `
              Pokaż detale
              `
            }
          </span>
        </p>
        {
          !isEn &&
          <div className="detale" style={{display: 'none'}}>
            Niektóre pliki cookies są wymagane do poprawnego działania tej strony stąd potrzeba ich zapisania w pamięci
            podręcznej przeglądarki. Więcej informacji pod adresem <a href="http://wszystkoociasteczkach.pl"
                                                                      target="_blank">www.wszystkoociasteczkach.pl</a><br/>
            <span className="ukryjdetale white underline">Ukryj detale</span>
          </div>
        }
      </div>
      <div className="large-2 medium-2 small-2 columns text-right">
        <span className="close">
          <img
            src={withPrefix(`images/cookies/close.svg`)}
            alt="Zamknij cookies"
            data-pin-nopin="true"
          />
        </span>
      </div>
    </div>
  </div>
)

export default Cookies
