import React from "react"

export default () => (
  <svg
    version="1.1"
    id="heart"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="65.583px"
    height="59.917px"
    viewBox="2.509 5.333 65.583 59.917"
  >
    <path
      fill="#7D3852"
      d="M66.789,16.872c-2.182-1.733-9.911-7.901-12.147-9.408
													c-0.996-0.67-1.724-0.958-2.468-1.005c-0.75,0.026-1.543,0.293-2.558,0.937c-2.354,1.501-11.717,8.557-13.529,9.922
													c-0.501-0.397-11.054-8.8-13.556-10.487c-0.996-0.67-1.842-0.958-2.586-1.005c-0.75,0.026-1.599,0.293-2.613,0.937
													C14.722,8.425,3.467,16.914,3.467,16.914s-0.248,0.158-0.248,1.003c0,0.757-0.051,20.364-0.051,24.099
													c0,0.647,0.467,1.118,0.748,1.329C7.445,45.995,31.43,62.099,33.804,63.7c0.996,0.669,1.847,0.958,2.592,1.005
													c0.749-0.026,1.604-0.293,2.619-0.937c2.61-1.663,26.481-19.123,27.276-19.773c1.375-1.125,1.209-3.089,1.209-3.089V18.658
													C67.5,18.658,67.291,17.27,66.789,16.872z M38.567,59.825c-0.708,0.61-1.439,0.855-2.118,0.86c-0.675-0.02-1.402-0.288-2.093-0.914
													c-0.194-0.177-19.101-12.56-27.284-18.623C6.959,41.064,6.5,40.84,6.5,39.968c0-3.521,0-19.452,0-20.146
													c0-0.793,0.076-0.74,0.9-1.336c4.234-3.064,10.1-7.641,10.264-7.781c0.709-0.61,1.498-0.855,2.178-0.86
													c0.674,0.02,1.432,0.288,2.121,0.914c0.196,0.179,8.695,6.905,12.336,9.893c1.565,1.283,2.18,1.024,3.096,0.367
													c3.747-2.688,12.522-9.507,12.725-9.679c0.708-0.61,1.443-0.855,2.122-0.86c0.675,0.02,1.401,0.288,2.092,0.914
													c0.159,0.146,5.067,4.72,9.161,7.956c0.174,0.136,0.213,21.679,0.057,21.796C55.711,47.09,38.765,59.657,38.567,59.825z"
    ></path>
  </svg>
)
